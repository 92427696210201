<template>
  <div class="el-input">
    <the-mask
      :mask="mask"
      class="el-input__inner"
      autocomplete="off"
      name="ssn"
      :masked="true"
      :value="value"
      @input.native="updateInput($event)"
      ref="ssnNumber" />
  </div>
</template>
<script>
import { getSupportedCountries, getDocumentTypes } from '@/utils/env';
import { TheMask } from 'vue-the-mask';
export default {
  name: 'SSNInput',
  components: { TheMask },
  props: {
    value: {
      type: String,
      default: ''
    },
    idType: {
      type: String,
      default: 'ssn'
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    isLast4: {
      type: Boolean,
      default: false
    },
    country: {
      type: String,
      default: 'US'
    }
  },
  mounted() {
    this.autofocus ? this.$refs.ssnNumber.$el.focus() : '';
  },
  computed: {
    selectedCountry() {
      const supportedCountries = getSupportedCountries();
      return supportedCountries.find(c => c.code === this.country);
    },
    selectedDocumentType() {
      const documentTypes = getDocumentTypes();
      const idType = this.idType || 'ssn';
      return documentTypes.find((d) => d.type === idType)
    },
    mask() {
      const type = this.selectedDocumentType;
      if(this.isLast4) {
        return 'XXXX';
      }
      return type.full;
    },
  },
  methods: {
    updateInput(event) {
      let value = event.target.value;
      if (value) {
        const validLengths = Array.isArray(this.mask) ? this.mask.map(m => m.length) : [this.mask.length]
        const data = {
          rawValue: value,
          value: value.replace(/[^0-9a-zA-Z]/g, ''),
          isValid: validLengths.includes(value.length),
          country: this.selectedCountry,
          documentType: this.selectedDocumentType
        };
        this.$emit('input', data)
      } else this.$emit('input', null);
    }
  }
};
</script>
<style lang="scss" scoped></style>
